.titleBanner{
    color:#fff;
    text-transform: uppercase;
    font-size: 2rem !important;
    font-weight: 900;
    margin-bottom: 0px;
    align-self: center;
    font-family: 'Montserrat', sans-serif;
    margin-top: 0px;
}
.subTitleBanner{
    color: #796116;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem !important;
}
.btnForm{
    padding: 10px 40px !important;
    background-color: var(--mainColor);
}
.formationIcon{
    justify-content: flex-start;
    display: flex;
    align-items: center;
}
.formationIcon span{
    margin-inline-start: 15px;
    text-transform: uppercase;
    width: 20%;
    text-align: center;
    font-size: 15px;
}
.formationIcon span::after{
    content: '';
    display: block;
    width: 100%;
    height: 3px;
}
.formationIcon .f1::after{
    background-color: #eab62c;
}
.formationIcon .f2::after{
    background-color: #c82143;
}
.formationIcon .f3::after{
    background-color: #0e4f85;
}
.formationIcon .f4::after{
    background-color: #2a2a56;
}
.formationIcon .f5::after{
    background-color: #ee6a37;
}
.formationIcon .f6::after{
    background-color: #33b670;
}
.FormMobileMobile{
    margin-top: 0px !important;
}
@media only screen and (max-width: 780px) {
    .titleBanner,
    .subTitleBanner{
        font-size: 2rem !important;
        margin-bottom: 20px;
    }
    .FormMobileMobile{
        display: block !important;
    }
    .mainBanner{
        position: static;
        margin-top: 20px;
    }
}
.css-uob957-MuiListSubheader-root{
    color: #000 !important;
    font-weight: bold !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    color: rgba(0, 0, 0, 0.6) !important;
}
.btnSubmit{
    width: 80% !important;
    font-size: 20px !important;
}
.titleDiplomeUpm{
    line-height: 30px;
}