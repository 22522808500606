.footer{
    background-color: #105495;
    color: #fff;
    padding: 10px 0px;
}
.footer a {
    text-decoration:none;
    color: gray;
}
.iconFooter img{
    width: 35px;
    height: 35px;
    margin-inline-end: 10px;
}
@media only screen and (max-width: 921px) {
    .footer{
      text-align: center;
    }
    .footer .iconFooter{
        text-align: center !important;
    }
}