.WhyUpm{
    padding: 20px 0px 0px;
}
.WhyUpm .row{
    position: relative;
}
.WhyUpm h2{
    font-weight: 900;
    margin-bottom: 10px;
    font-family: 'Roboto',sans-serif;
    padding: 10px;
}
.WhyUpm .contenu{
    text-align: justify;
    padding: 10px;
    font-family: 'Roboto',sans-serif;

}
.FormMobileMobile{
    display: none;
}
.WhyUpm .whyUpmImage{
    border-radius: 20px;
}
.subTitle{
    font-size: 15px;
    color: var(--mainColor);
    padding: 10px;
    font-weight: 700;
}
.formWhy{
    position: absolute;
    top: -20%;
    left: 55%;
}
.formWhy,.FormMobileMobile{
    padding:20px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 99;
    box-shadow: 9px 15px 45px -9px rgba(0,0,0,0.45);
    -webkit-box-shadow: 9px 15px 45px -9px rgba(0,0,0,0.45);
    -moz-box-shadow: 9px 15px 45px -9px rgba(0,0,0,0.45);
    margin-top: -150px;
}

.formWhy h3,.FormMobileMobile h3{
    color: var(--mainColor);
}
.formWhy label,.FormMobileMobile label{
    color: #000;
}
.bg-input{
    background-color: #e8e8e8;
    margin-top: 10px !important;
}
.formWhy fieldset
{
    border-color: #efefef;
    /* background-color: #e8e8e8; */
}

@media only screen and (max-width: 921px) {
    .WhyUpm .formWhy{
        display: none !important;
    }
    .WhyUpm .contenu{
        text-align: start;
    }
    .ListOfFormation{
        margin-top: 0px !important;
    }
    .formWhy fieldset,.FormMobileMobile label{
        background-color: transparent !important;
    }
    .FormMobileMobile{
        padding: 20px !important;
    }
    .inputClass{
        margin-top: 5px !important;
    }
    .FormMobileMobile{
        background-color: #efefef !important;
        margin-top: 36px !important;
        /* border: 1px solid #000; */
        width: 95%;
        margin-inline: auto;
    }
    .titleDiplomeUpm{
        line-height: 1.8;
    }
    .contenuBas{
        padding-left: 25px !important;
    }
    .FormMobileMobile label{
        border-color: #efefef;
        background-color: #e8e8e8;
    }
}
.StrepperClass svg.Mui-active{
    color: var(--mainColor) !important;
}
.StrepperClass svg{
    color: #fff !important;
    /* border: 2px solid var(--mainColor);
    border-radius: 50%; */
}
.StrepperClass text{
    fill: #000;
}
.btnSubmit{
    background-color: var(--mainColor) !important;
}