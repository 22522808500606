.btnHeader{
    padding: 10px 30px !important;
    background-color: #fff !important;
    border-color: #fff !important;
    color: var(--mainColor) !important;
    transition: all .6s ease-in-out;
}
.btnHeader:hover{
    background-color: var(--mainColor) !important;
    color: #fff !important;
}
@media only screen and (max-width: 921px) {
    .logoUpm{
        width: 150px !important;
    }
}