@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;700&family=Montserrat:wght@700;800&family=Open+Sans:wght@300;700&family=Poppins:wght@200;400&display=swap');
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Roboto',sans-serif;
}
:root{
    --mainColor:#085496
}
body{
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
}
.css-i4bv87-MuiSvgIcon-root,
.MuiAutocomplete-input,
.SelectForamtion .MuiSelect-select
{
    color: #000;
    z-index: 999;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color: #efefef !important;
}
.float-right{
    float: right;
}
html{
    scroll-behavior: smooth;
}
.MainHeader{
    background-color: #0057a333;
    background-image: url('../images/UPM\ header.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
}
.title{
    color: #0057a3;
}
.title::after{
    content: "";
    display: block;
    width: 10%;
    height: 3px;
    background-color: #0057a3;
    margin-inline: auto;
    margin-top: 10px;
    font-weight: bold;
}
.mainBanner{
    position: absolute;
    top: 35%;
}
@media only screen and (max-width: 921px) {
    .MainHeader{
        height: auto !important;
    }
    .WhyUpm .pourqouiUpm{
        margin-top: 0px !important;
    }
    .MuiFormHelperText-sizeMedium{
        background-color: #efefef !important;
    }
    .MainHeader{
        background-image: url('../images/UpmHeaderMobile.jpg');
        background-position: initial;
    }
}
.bgBlueUpm{
    background-color: #085496;
    padding: 5px;
    border-radius: 2px;
    margin-right: 2px;
}
.btnForm{
    background-color: #085496 !important;
}
.MuiFormHelperText-sizeMedium{
    background: #fff;
    margin: 0 !important;
}
.react-tel-input .form-control{
    width: 100% !important;
    /* padding: 16.5px 32px; */
    height: 45px !important;
    background-color: #e8e8e8 !important;
    border: none;
}
.politiqueText{
    font-size: 12px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    font-size: 11px !important;
}
.slick-dots{
    position: static !important;
}
.slick-arrow.slick-next{
    right: 35px !important;
    z-index: 99;
}
.SlideAvis .slick-arrow.slick-next::before,
.SlideAvis .slick-arrow.slick-prev::before
{
    color: #000 !important;
}
.slick-arrow.slick-prev{
    left: 25px !important;
    z-index: 99;
}